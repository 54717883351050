<template>
  <b-card
    :flat="true"
    no-body
    :class="`${true ? 'shadow-none' : ''}`"
    style="height: 100%"
  >
    <b-row class="p-1">
      <div class="ml-1 mt-1 mb-1 text-lg font-bold">Case Import</div></b-row
    >
    <b-overlay :show="endpointBusy">
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="w-full text-center"
        >
          <p>
            <b-spinner small label="Small Spinner"></b-spinner>
          </p>
        </div>
      </template>
      <b-row class="p-1">
        <b-col lg="4">
          <div>
            <div :class="`${selectedCountyError ? 'text-red-400' : ''}`">
              <span v-if="selectedCountyError">no county chosen </span>
              <span v-else>Upload County </span>
            </div>

            <b-form-select
              v-model="selectedCounty"
              :options="selectedCountyOptions"
              size="sm"
              class="border-red"
            >
            </b-form-select>
          </div>
        </b-col>
        <b-col lg="4">
          <div>
            <div :class="`${selectedTypeError ? 'text-red-400' : ''}`">
              <span v-if="selectedTypeError">no status chosen </span>
              <span v-else>Status of Cases to be Uploaded </span>
            </div>
            <b-form-select
              v-model="selectedType"
              :options="typeOptions"
              size="sm"
            ></b-form-select>
          </div>
        </b-col>
        <b-col lg="4">
          <div>
            <div :class="`${uploadError != null ? 'text-red-400' : ''}`">
              <span v-if="uploadError != null">{{ errorMessage }} </span>
              <span v-else>Select File to Upload </span>
              <span class="text-xs">(.xls)</span>
            </div>
            <b-input-group size="sm">
              <b-form-file
                v-model="file1"
                size="sm"
                :state="uploadError"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-input-group></div
        ></b-col>
        <b-col>
          <div>
            <div class="invisible">button</div>
            <b-button size="sm" @click="uploadFile()"> IMPORT CASES </b-button>
          </div></b-col
        >
      </b-row>
    </b-overlay>

    <b-row class="mx-1">
      <b-col lg="4" style="width: 100%">
        <b-row class="p-1">
          <b-list-group style="width: 100%">
            <div class="w-full border-b-default my-1" style="width: 100%">
              Processing
            </div>
            <b-list-group-item v-for="(item, index) in processing" :key="index">
              <b-col>
                <b-row>
                  <span class="font-bold mr-3 text-primary"
                    >Processing
                    <b-spinner small label="Small Spinner"></b-spinner>
                  </span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">County:</span>
                  <span class="text-sm">{{ item["county"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">File Name:</span>
                  <span class="text-sm">{{ item["filename"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Case Status:</span>
                  <span class="text-sm">{{ item["case_status"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Import Time:</span>
                  <span class="text-sm">{{ item["start_time"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Total Cases:</span>
                  <span class="text-sm">{{ item["total_cases"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Current Case:</span>
                  <span class="text-sm">{{ item["current_case"] }}</span>
                </b-row>
              </b-col>
            </b-list-group-item>
          </b-list-group>
        </b-row>
      </b-col>

      <b-col lg="4" style="width: 100%">
        <b-row class="p-1">
          <b-list-group style="width: 100%">
            <div class="w-full border-b-default my-1" style="width: 100%">
              Failed Today
            </div>
            <b-list-group-item v-for="(item, index) in failed" :key="index">
              <b-col>
                <b-row>
                  <span class="font-bold mr-3 underline text-danger"
                    >** Failed **</span
                  >
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">County:</span>
                  <span class="text-sm">{{ item["county"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">File Name:</span>
                  <span class="text-sm">{{ item["filename"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Case Status:</span>
                  <span class="text-sm">{{ item["case_status"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Total Cases:</span>
                  <span class="text-sm">{{ item["total_cases"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Import Start Time:</span>
                  <span class="text-sm">{{ item["start_time"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Failed Time:</span>
                  <span class="text-sm">{{ item["end_time"] }}</span>
                </b-row>
              </b-col>
            </b-list-group-item>
          </b-list-group>
        </b-row>
      </b-col>

      <b-col lg="4" style="width: 100%">
        <b-row class="p-1">
          <b-list-group style="width: 100%">
            <div class="w-full border-b-default my-1" style="width: 100%">
              Recent Successful Imports
            </div>
            <b-list-group-item v-for="(item, index) in complete" :key="index">
              <b-col>
                <b-row>
                  <span class="font-bold mr-3 underline text-success"
                    >Complete!</span
                  >
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">County:</span>
                  <span class="text-sm">{{ item["county"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">File Name:</span>
                  <span class="text-sm">{{ item["filename"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Case Status:</span>
                  <span class="text-sm">{{ item["case_status"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Total Cases:</span>
                  <span class="text-sm">{{ item["total_cases"] }}</span>
                </b-row>
                <b-row>
                  <span class="font-bold mr-3">Import Time:</span>
                  <span class="text-sm">{{ item["start_time"] }}</span>
                </b-row>

                <b-row>
                  <span class="font-bold mr-3">Completed Time:</span>
                  <span class="text-sm">{{ item["end_time"] }}</span>
                </b-row>
              </b-col>
            </b-list-group-item>
          </b-list-group>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>
  
  
  <script>
import {
  BCol,
  BRow,
  BCard,
  BButton,
  BInputGroup,
  BFormSelect,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCol,
    BRow,

    BCard,
    //BCardBody,
    BButton,

    BInputGroup,

    BFormSelect,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BSpinner,
    BOverlay,
  },
  data() {
    return {
      file1: null,
      endpointBusy: true,
      selectedCounty: "select a county",
      selectedCountyOptions: [],
      uploadError: null,
      errorMessage: "no file chosen",
      selectedCountyError: null,
      selectedType: "choose a type",
      time: 0,
      isRunning: false,
      interval: null,
      busy: false,
      show: false,
      processing: [],
      failed: [],
      complete: [],
      typeOptions: [
        "choose a type",
        "filed",
        "served",
        "hearing",
        "disposed",
        "garnish",
      ],
      selectedTypeError: null,
      adminTemplateId: "",
      adminTemplates: [],
    };
  },
  watch: {
    async time(newValue) {
      if (newValue > 3) {
        if (!this.busy) {
          this.getUploads();
          this.show = true;
          await setTimeout(() => console.log(``), 1000);
          this.show = false;
        }
        this.time = 0;
      }
    },
  },
  created() {
    this.getUploads();
    this.toggleTimer()
    this.fetchChoices();
  },
  methods: {
    fetchChoices() {
      var l1 = this;
      l1.$AuthAxios.instance
        .get("/api-access/exports/fetch-choices/", {
          params: { choices: [{ county: "allowed_imports" }] },
        })
        .then(function (res) {
          l1.selectedCountyOptions = res.data.counties;
          l1.selectedCountyOptions.push("select a county");

          l1.endpointBusy = false;
        })
        .catch((error) => {
          l1.endpointBusy = false;
          console.log(error);
        });
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
        console.log("timer stops");
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },
    uploadFile() {
      if (this.selectedType === "choose a type") {
        this.selectedTypeError = true;
      } else {
        this.selectedTypeError = false;
      }

      if (this.selectedCounty === "select a county") {
        this.selectedCountyError = true;
      } else {
        this.selectedCountyError = false;
      }

      if (!this.file1) {
        this.uploadError = true;
      } else {
        this.uploadError = false;
      }

      if (
        !this.selectedTypeError &&
        !this.selectedCountyError &&
        !this.uploadError
      ) {
        this.endpointBusy = true;
        this.uploadError = null;
        const formData = new FormData();
        formData.append("file", this.file1);
        formData.append("county", this.selectedCounty);
        formData.append("uploadType", this.selectedType);
        const headers = { "Content-Type": "multipart/form-data" };
        this.$AuthAxios.instance
          .put("/api-access/tn_crawl_api/case-upload/", formData, { headers })
          .then((res) => {
            this.endpointBusy = false;
            res.data.files; // binary representation of the file
            res.status; // HTTP status

            this.getUploads();
          })
          .catch((error) => {
            this.uploadError = false;
            this.errorMessage = error.response.data;
            this.endpointBusy = false;
          });
      }
    },
    getUploads() {
      this.busy = true;
      this.$AuthAxios.instance
        .get("/api-access/tn_crawl_api/case-upload/", {})
        .then((res) => {
          this.processing = res.data.processing;
          this.complete = res.data.complete;
          this.failed = res.data.failed;
          this.busy = false;
        })
        .catch((error) => {
          this.uploadError = false;
          this.errorMessage = error.response.data;
        });
    },

    setBaseTemplate() {
      var l1 = this;

      if (!l1.selectedCounty) {
        l1.selectedCountyError = true;
      } else {
        l1.selectedCountyError = false;
      }

      if (!l1.selectedType) {
        l1.selectedTypeError = true;
      } else {
        l1.selectedTypeError = false;
      }

      if (l1.adminTemplateId) {
        l1.uploadError = true;
      } else {
        l1.uploadError = false;
      }

      if (l1.selectedCounty && l1.selectedType && l1.adminTemplateId) {
        l1.$AuthAxios.instance
          .post("/api-access/exports/base-templates/", {
            params: {
              user: l1.selectedCounty,
              type: l1.selectedType,
              id: l1.adminTemplateId,
            },
          })
          .then(function (res) {
            l1.adminTemplates = res.data;
          })
          .catch((error) => {
            if (error.response.status === 406) {
              const toaster = "b-toaster-bottom-full";
              l1.$toast({
                component: ToastificationContent,
                props: {
                  title: "That Template does not exist!",
                  toaster,
                  solid: true,
                  icon: "EditIcon",
                  variant: "warning",
                  //"Your export name has already been used. Choose a new name."
                },
              });
            }
          });
      }
    },
  },
};
</script>

  