<template>

    <div c>
    <case-uploader/>
  </div>
    
  </template>
  
  <script>

  import CaseUploader from "@/views/components/case-upload/CaseUploader.vue"
  export default {
    components: {
        CaseUploader
    },
    data() {
      return {
        contentWidth: this.$store.state.appConfig.layout.contentWidth,
      };
    },
    created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },

  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth);
  },
 
    methods: {

    },
  };
  </script>

  